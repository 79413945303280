import { QuizRenderer } from 'components/quiz/Renderer'
import { PageProps } from 'gatsby'
import { useGetCourseDetails } from '../../../../../hooks/useGetCourseDetails'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../../../redux/hooks'
import { QuizStateActions } from '../../../../../redux/slices/QuizState'
import Loader from 'react-js-loader'
import Cam from './cam.js'
import 'styles/index.scss'
import { CustomModal } from '../../../../../components/common/useModal'
import { useState } from 'react'
import { Form } from '../../../../../components/Form/Form'
import { addFileToCDN } from './addFileToCDN'
import axios from 'axios'
import { getUrl } from '../../../../../API/getUrl'
import { Icon } from '@iconify/react'
import { getDefaultOptions } from '../../../../../API/auth'
import { useGetModulesHook } from '../../../../../hooks/useGetModulesHook'
import { useEditableHook } from '../../../../../hooks/useEditableHook'
import { getQuiz } from '../../../../../API/getQuiz'
const ProctorQuiz: React.FC<PageProps> = ({ params: { id }, location }) => {
	const { ShowDetails, course, viewAs, type_of_course, stable } = useGetCourseDetails(id)
	const [gradingCurrently, setGradingCurrently] = useState<boolean>(false)
	const [isGraded, setIsGraded] = useState<boolean>(false)
	// const [asset, setAsset] = React.useState<PromiseType<ReturnType<typeof getQuiz>>>()
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(QuizStateActions.reset())
	}, [])
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const { edit, elem_id } = useEditableHook({ location })

	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getQuiz({ quiz_id: elem_id, viewAs: 'Teacher', id: id })
				if (asset) {
					console.log('asset', asset)
					// setAsset(data)

					dispatch(
						QuizStateActions.setState({
							questionData: {
								...asset,
							},
							questions: asset.questions,
						})
					)
				}
			}
			run()
		}
	}, [edit, elem_id])

	const { run: getSortedModules } = useGetModulesHook({ course: id, stable, type_of_course })

	const [modules, setModules] = useState<any>([])

	useEffect(() => {
		if (type_of_course && stable) {
			const run = async () => {
				const value = await getSortedModules()
				console.log(value)
				if (value) {
					setModules(value)
				} else {
					setModules([])
				}

				// setModules(await getSortedModules())
			}
			run()
		}
	}, [type_of_course, stable])
	// const [videoRef, setvideoRef] = useState(null)
	const [loading, setLoading] = useState(true)

	// const videoRef = useRef(null)
	// const photoRef = useRef(null)
	// const getVideo = () => {
	// 	navigator.mediaDevices
	// 		.getUserMedia({
	// 			video: { width: 1000, height: 1000 },
	// 		})
	// 		.then((stream) => {
	// 			const video = videoRef.current
	// 			video.srcObject = stream
	// 			video.play()
	// 		})
	// 		.catch((err) => {
	// 			console.error(err)
	// 		})
	// }
	// useEffect(() => {
	// 	getVideo()
	// }, [videoRef])
	return (
		<>
			{/* <div className='px-2'>
				<h2>Add Quiz</h2>
				<QuizRenderer course={course?.course.uid ?? ''} renderAs={viewAs === 'Teacher' ? 'edit' : 'attempting'}></QuizRenderer>
			</div> */}
			<CustomModal
				header={edit ? 'Edit Quiz' : 'Add Quiz'}
				onClick={() => {
					// console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				{ShowDetails}
				{viewAs === 'Teacher' && gradingCurrently === false && (
					<>
						<div className=''>
							<button onClick={() => window.open('/Sample Quiz.xlsx')} className='btn btn-primary'>
								<Icon icon='bx:bx-download' className='px-2'></Icon>
								Download Question Bank Sample File
							</button>
						</div>
						<Form
							expanded={true}
							fields={[
								{
									label: 'file',
									name: 'file',
									type: 'image',
								},
							]}
							action={async ({ state }) => {
								// console.log({ state })
								const cdn = await addFileToCDN({ ...state, name: 'quiz-file.json' })
								if (cdn) {
									setLoading(false)
									const { questions } = (
										await axios.get<{ questions: any[] }>(getUrl(`cdn/quiz_json?cdn_id=${cdn.uid}`), getDefaultOptions())
									).data
									dispatch(QuizStateActions.setState({ questions }))
									setLoading(true)
								}
							}}
							text={loading ? 'Upload Question Bank' : <Loader type='spinner-circle' bgColor={'#1266cc'} size={100} />}
						></Form>
					</>
				)}
				{process.env.NODE_ENV === 'development' && (
					<>
						<button onClick={() => setGradingCurrently((gradingCurrently) => !gradingCurrently)} className='toggle'>
							Toggle gradingCurrently
						</button>
						gradingCurrently - {gradingCurrently ? 'true' : 'false'}
						<br />
						<button onClick={() => setIsGraded((isGraded) => !isGraded)} className='toggle'>
							Toggle isGraded
						</button>
						isGraded - {isGraded ? 'true' : 'false'}
					</>
				)}
				{/* <Cam></Cam> */}
				<QuizRenderer
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					quiz_uid={elem_id}
					edit={edit === 'true'}
					course={course?.course.uid ?? ''}
					proctor={true}
					modules={modules}
					renderAs={viewAs === 'Teacher' ? (gradingCurrently ? 'grading' : 'edit') : 'attempting'}
				></QuizRenderer>
			</CustomModal>
		</>
	)
}

export default ProctorQuiz
